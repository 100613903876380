import Constants from "./helper/constants";
import CustomArrowLink from "./helper/customArrowLink";
import Img from "./helper/Img"
import CustomLink from './helper/customlink';
import MediaQuery from 'react-responsive';
import CenterTextDiv from "./helper/centerTextDiv";
import BgImg from "./helper/bgImg";
import ProduceContent from './helper/functions';

import { useSelector, useDispatch } from 'react-redux';

var styles = {
  contact: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    paddingTop: (Constants.headerHeight + 30),
    color: Constants.colors.white,
    textAlign: 'center',
  },
  contactMobile: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    marginTop: -1 * (Constants.headerHeight + Constants.footerHeight),
    backgroundColor: Constants.colors.white,
    color: Constants.colors.black,
    textAlign: 'center',
  },
  mobileImageContainer: {
    paddingTop: Constants.headerHeight + Constants.footerHeight,
  },
  contactImage: {
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
  },
};


const Contact = () => {
  function createEmailText() {
    return (
      <div style={{paddingRight: 30, paddingLeft: 30}}>
        <p>
          To contact Chase about Berklee College of Music, please email at
          <CustomLink 
            of={' cmorrin@berklee.edu'} 
            url={'mailto:cmorrin@berklee.edu'}
            style={{
              color: Constants.colors.gray}}
            hoverStyle={{
              color: 'gray'}}/>
        </p>
        <p>
          To contact Chase about New England Conservatory Preparatory School, please email at
          <CustomLink 
            of={' chasemorrin@necmusic.edu'} 
            url={'mailto:chasemorrin@necmusic.edu'}
            style={{
              color: Constants.colors.gray}}
            hoverStyle={{
              color: 'gray'}}/>
        </p>
        <p>
          For booking, teaching, and all other inquiries, please email Chase at
          <CustomLink 
            of={' chasemorrin@gmail.com'} 
            url={'mailto:chasemorrin@gmail.com'}
            style={{
              color: Constants.colors.gray}}
            hoverStyle={{
              color: 'gray'}}/>
        </p>
      </div>
    );
  }

  function createSubscribeText() {
    return (
        <div style={{height: 330, display: 'flex', justifyContent: 'center'}}>
          <iframe src={'https://dashboard.mailerlite.com/forms/756564/109550464653067369/share'} style={{border: 'none', width: 340, height: 332, overflow: 'hidden'}}></iframe>
        </div>
    );
  }

  return (
    <>
      <MediaQuery minWidth={800}>
        <div style={styles.contact}>
          <CenterTextDiv centerHeaderContent={'Contact'}/>
          <BgImg darken={.5}  src={'img/pagephotos/contactbackground.jpg'}/> 
          {createEmailText()}  
          <div style={{height: 40}}></div>
          <CenterTextDiv centerHeaderContent={'Follow by Email'}/>
          {createSubscribeText()}
          <div style={{height: 40}}></div>
          <CenterTextDiv centerHeaderContent={'Follow on Instagram'}/>
          <div style={{width: 400}}>
            <CustomArrowLink of={<Img src={'img/extrachase/ChaseInstaImage.png'}/>} url={'https://www.instagram.com/chase_morrin/'}/>
          </div>
          <div style={{height: 40}}></div>
        </div>
      </MediaQuery> 
      <MediaQuery maxWidth={800}>
        <div style={styles.contactMobile}>
          <div style={styles.mobileImageContainer}>
            <Img style={{opacity: 1}} src={'img/pagephotos/contactbackground.jpg'}/>
            <div style={{height: 20}}></div>
            <CenterTextDiv centerHeaderContent={'Contact'}/>
            {createEmailText()} 
            <div style={{height: 20}}></div>
            <CenterTextDiv centerHeaderContent={'Follow by Email'}/>
            {createSubscribeText()} 
            <div style={{height: 30}}></div>
            <CenterTextDiv centerHeaderContent={'Follow on Instagram'}/>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{width: 300}}>
              <CustomArrowLink of={<Img src={'img/extrachase/ChaseInstaImage.png'}/>} url={'https://www.instagram.com/chase_morrin/'}/>
              </div>
            </div>
            <div style={{height: 30}}></div>
          </div>
        </div>
      </MediaQuery> 
    </>
  );
};

export default Contact;