import { Link } from "react-router-dom";
import Radium from "radium";
import { useState } from 'react';

var RadiumLink = Radium(Link);

/**
 * Example:
 * <CustomLink 
 *  of={'Click Me'}
 *  url={'url.com'}
 *  style={{color: 'black'}}
 *  hoverStyle={{color: 'white'}}
 *  newtab={true}
 * />
 */

var styles = {
  defaultHover: {
    opacity: .5,
  },
  default: {
    opacity: 1,
  },
  all: {
    textDecoration: 'none',
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
    textAlign: 'center',
  },
  icon: {
    marginLeft: 0,
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
  },
  iconHover: {
    marginLeft: 5,
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
  },
};

const CustomLink = ({url = null, of = null, style = styles.default, hoverStyle = styles.defaultHover, arrow = null}) => {
  const [isHovering, setIsHovering] = useState(false);

  function handleMouseOver() {
    setIsHovering(true);
  }

  function handleMouseOut() {
    setIsHovering(false);
  }

  return (
    <span 
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{display: 'flex', alignItems: 'center'}}>
      {!url.includes("www") & !url.includes("http") ? 
        
        <RadiumLink 
          key={'link'}
          to={url}
          style={isHovering ? [styles.all, hoverStyle] : [styles.all, style]}
        >
          {of}
        </RadiumLink>
        : <a 
        key={'link'}
        style={isHovering ? [styles.all, hoverStyle] : [styles.all, style]}
        href={url}
        target="_blank"
      >
        {of}
      </a>}
      {arrow ? 
        <div style={{marginLeft: 10, width: 20}}>
          <i 
            key={'right arrow'}
            className="fa fa-arrow-right" 
            style={isHovering ? [styles.iconHover, hoverStyle] : [styles.icon, style]}
          ></i>
        </div>
        : null}
    </span>
  );  
};

export default Radium(CustomLink);